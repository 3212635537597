import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { logout } from 'src/auth'
import { resetUser } from 'src/auth/utils/resetUser'
import { NetworkState } from 'src/constants/network-state'
import AbilityRoleApiService from 'src/services/switch-rest-api/rest-ability-role.service'
import { AbilityRole } from 'switch-shared/entities/ability-role.entity'

const abilityRoleApiService = new AbilityRoleApiService()

interface AbilityRoleInterface {
    status: NetworkState
    abilityRoles: AbilityRole[]
    error: string | undefined
}

const initialState: AbilityRoleInterface = {
    abilityRoles: [],
    status: NetworkState.NOT_STARTED,
    error: undefined,
}

export const fetchAbilityRole = createAsyncThunk(
    'ability-role/fetchAbilityRole',
    async (userId: string) => {
        try {
            const res = await abilityRoleApiService.getAbilityRole({
                userId,
            })
            return res?.value
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

const abilityRoleSlice = createSlice({
    name: 'ability-role',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(resetUser.rejected, (state, action) => {
                state.status = NetworkState.ERROR
                state.abilityRoles = []
                state.error = action.error.message
            })
            .addCase(fetchAbilityRole.pending, (state, { payload }) => {
                state.status = NetworkState.LOADING
            })
            .addCase(fetchAbilityRole.fulfilled, (state, action) => {
                state.abilityRoles = action.payload
                state.status = NetworkState.SUCCESS
            })
            .addCase(fetchAbilityRole.rejected, (state, { payload }) => {
                state.status = NetworkState.ERROR
                /* 
          TODO: Instead of routing to login if get ability roles fail, it should login but with no permissions
        */
                logout()

                toast.error(
                    'There has been an error getting role details. Please try logging in'
                )
            })
            .addCase(resetUser.fulfilled, () => {
                return initialState
            })
    },
})

export default abilityRoleSlice.reducer
