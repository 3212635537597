import { baseAPI } from './baseAPI.service'
import { API_METHODS, ENDPOINT_URLS, Endpoint } from './config/apiTags'
import { SearchAlertsOutput } from 'switch-shared/dtos/alert.dto'
import { SearchAlertsQuery } from 'switch-shared/dtos/alert.dto'

export const alertAPI = baseAPI
    .enhanceEndpoints({
        addTagTypes: [Endpoint.ALERT],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            alertFind: builder.query<SearchAlertsOutput, SearchAlertsQuery>({
                query: (params) => ({
                    url: `${ENDPOINT_URLS.ALERT}`,
                    method: API_METHODS.GET,
                    params,
                }),
                providesTags: [Endpoint.ALERT],
            }),

            alertDelete: builder.mutation<void, { id: string }>({
                query: ({ id }) => ({
                    url: `${ENDPOINT_URLS.ALERT}/${id}`,
                    method: API_METHODS.DELETE,
                }),
                invalidatesTags: [Endpoint.ALERT],
            }),
        }),
    })

export const { useAlertFindQuery, useAlertDeleteMutation } = alertAPI
export default alertAPI
