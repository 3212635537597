import BaseApiService, {
    ResultWithHttpCode,
    ResultWithValueAndHttpCode,
} from './rest-base.service'
import { ApplicationGroup } from 'switch-shared/entities/application-group.entity'
import { UpdateDto } from 'switch-shared/services/crud/base.service'

class ApplicationGroupApiService extends BaseApiService {
    constructor() {
        super(process.env.REACT_APP_BASE_API_URL?.slice(0, -1))
    }

    public async getAppGroups(params: {
        page?: number
        limit?: number
        applicationGroupId?: string
    }): Promise<ResultWithValueAndHttpCode<any> | undefined> {
        const apiResult: ResultWithValueAndHttpCode<any> =
            await this.getWithParams<any>(`application-group`, params)
        if (!apiResult.isSuccess) {
            return await Promise.reject(
                new Error(
                    JSON.stringify({
                        isSuccess: false,
                        value: null,
                        errorMessage: apiResult.errorMessage,
                        httpCode: apiResult.httpCode,
                    })
                )
            )
        }
        return {
            isSuccess: true,
            value: apiResult.value,
            errorMessage: '',
            httpCode: apiResult.httpCode,
        }
    }

    // eslint-disable-next-line consistent-return
    public async createAppGroup(newAppGroupObject: {
        name: string
    }): Promise<ResultWithValueAndHttpCode<any> | undefined> {
        const apiResult: ResultWithValueAndHttpCode<any> | undefined =
            await this.post<any>(`application-group`, newAppGroupObject)
        if (apiResult !== undefined) {
            if (!apiResult.isSuccess) {
                return await Promise.reject(
                    new Error(
                        JSON.stringify({
                            isSuccess: false,
                            value: null,
                            errorMessage: apiResult.errorMessage,
                            httpCode: apiResult.httpCode,
                        })
                    )
                )
            }

            return {
                isSuccess: true,
                value: newAppGroupObject,
                errorMessage: '',
                httpCode: apiResult.httpCode,
            }
        }
    }

    public async deleteAppGroup(
        appToDeleteId: string
    ): Promise<ResultWithHttpCode | undefined> {
        const apiResult: ResultWithHttpCode | undefined = await this.delete(
            `application-group/${appToDeleteId}`
        )
        if (apiResult !== undefined) {
            if (!apiResult.isSuccess) {
                return await Promise.reject(
                    new Error(
                        JSON.stringify({
                            isSuccess: false,
                            value: null,
                            errorMessage: apiResult.errorMessage,
                            httpCode: apiResult.httpCode,
                        })
                    )
                )
            }
            return {
                isSuccess: true,
                errorMessage: '',
                httpCode: apiResult.httpCode,
            }
        }
    }

    public async updateAppGroup(
        id: string,
        appGroup: UpdateDto<ApplicationGroup>
    ): Promise<ResultWithValueAndHttpCode<any> | undefined> {
        const apiResult: ResultWithValueAndHttpCode<any> | undefined =
            await this.patch<any>(`application-group/${id}`, appGroup)
        if (apiResult !== undefined) {
            if (!apiResult.isSuccess) {
                return await Promise.reject(
                    new Error(
                        JSON.stringify({
                            isSuccess: false,
                            value: null,
                            errorMessage: apiResult.errorMessage,
                            httpCode: apiResult.httpCode,
                        })
                    )
                )
            }
            return {
                isSuccess: true,
                value: apiResult.value,
                errorMessage: '',
                httpCode: apiResult.httpCode,
            }
        }
    }
}
export default ApplicationGroupApiService
