import { MongoAbility, createMongoAbility, defineAbility } from '@casl/ability'
import { ROLES } from 'src/constants/roles'
import { AbilityRole } from 'switch-shared/entities/ability-role.entity'
import { Application } from 'switch-shared/entities/application.entity'
import { ApplicationGroup } from 'switch-shared/entities/application-group.entity'
import { TariffStructure } from 'switch-shared/modules/tariff-structures/tariff-structure.entity'

const classSubjects: any[] = [
    // AbilityRole,
    // UtilityData,
    // Invoice,
    // ModbusCommand,
    // UserInvite,
    // ApiKey,
    // Account,
    // ApplicationGroup,
    // Application,
    // Meter,
    // MeterPoint,
    // Notification,
    // Payment,
    // Report,
    // Tariff,
    // Transaction,
    // User,
]

const validSubjects = [
    'all',
    ...classSubjects,
    ...classSubjects.map((cl) => new cl()),
    'AbilityRole',
    'ApiKey',
    'Account',
    'ApplicationGroup',
    'Application',
    'LoadSheddingSchedule',
    'Meter',
    'MeterPoint',
    'Invoice',
    'ModbusCommand',
    'UserInvite',
    'Payment',
    'Notification',
    'Report',
    'Tariff',
    'Transaction',
    'User',
    'UtilityData',
    'AbilityRoles',
    'ApiKeys',
    'Accounts',
    'ApplicationGroups',
    'Applications',
    'LoadSheddingSchedules',
    'Meters',
    'MeterPoints',
    'Invoices',
    'ModbusCommands',
    'UserInvites',
    'BillingRuns',
    'Payments',
    'Alert',
    'Alerts',
    'Reports',
    'Tariffs',
    'Transactions',
    'Users',
    'UtilityData',
    'ApplicationUtilities',
    'ScheduledReports',
    'Gateways',
    'ReportLayouts',

    //THE FOLLOWING ARE TEMPORARY FOR OLD ABILITYROLES AND ARE NOT ACTUALLY USED
    'CustomReports',
    'Recons',
    'AbilityRolesModel',
    'Network',
    'Map',
] as const

export const mobileValidSubjects = [
    'AbilityRoles',
    'Accounts',
    'Meters',
    'MeterPoints',
    'Alert',
    'Alerts',
    'Transactions',
    'Users',
    'PushNotifications',
] as const

const toExcludeSubjects = [
    'all',
    'ApiKeys',
    'AbilityRoles',
    'Tariffs',
    'ApiKey',
    'SensorData',
    'Sensor',
    'Sensors',
    'LoadSheddingSchedules',
    'LoadSheddingSchedule',
    'IotCoreLog',
    'Recons',
    'Recon',
]

export const adminValidSubjects = [
    ...validSubjects.filter((ele) => !toExcludeSubjects.includes(ele)),
] as const

type IsString<T> = T extends string ? T : never
export type Subject = (typeof validSubjects)[number]
export type SubjectString = IsString<Subject>

export type Policy = (abilityRole?: AbilityRole) => Ability
export type Ability = MongoAbility<[Action, Subject]>

const validActions = ['manage', 'create', 'read', 'update', 'delete'] as const

export enum ACTIONS {
    MANAGE = 'manage',
    CREATE = 'create',
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete',
}

export type Action = (typeof validActions)[number]

// export function parseAction(actionStr: string): Action {
//     for (const action of validActions) {
//       if (action === actionStr) return action;
//     }

//     throw new Error(`String '${actionStr}' is not a valid Action.`);
// }

// export function parseSubject(subjectStr: string): SubjectString {
//     for (const subject of validSubjects) {
//       if (subject === subjectStr) return subject;
//     }

//     throw new Error(`String '${subjectStr}' is not a valid Subject.`);
// }

export function parseAction(actionStr: string): Action {
    for (const action of validActions) {
        if (action === actionStr) return action
    }

    throw new Error(`String '${actionStr}' is not a valid Action.`)
}

export function parseSubject(subjectStr: string): SubjectString {
    for (const subject of validSubjects) {
        if (subject === subjectStr) return subject
    }

    throw new Error(`String '${subjectStr}' is not a valid Subject.`)
}

export const defineAbilityRole: Policy = (abilityRole?: AbilityRole) => {
    if (!abilityRole) {
        console.warn(`Role does not have a defined policy.`)
        return defineAbility<Ability>((_can, cannot) => {
            cannot('manage', 'all')
        })
    }

    switch (abilityRole.role) {
        case ROLES.SUPER_ADMIN:
            return defineAbility<Ability>((can) => {
                can('manage', 'all')
            })

        case ROLES.GROUP_ADMIN:
            return defineAbility<Ability>((can, cannot) => {
                for (const entity of validSubjects) {
                    cannot('manage', entity)
                }

                for (const entity of adminValidSubjects) {
                    can('manage', entity)
                }

                can<AbilityRole>('manage', 'AbilityRoles', {
                    role: {
                        $in: [
                            ROLES.GROUP_ADMIN,
                            ROLES.READ_ONLY,
                            ROLES.CUSTOM,
                            ROLES.MOBILE,
                        ],
                    },
                })

                for (const scope of abilityRole.scope) {
                    if (
                        !scope.applicationGroupId ||
                        !(scope.allApplications || scope.applicationIds)
                    ) {
                        console.warn(
                            `Invalid scopes for role '${abilityRole.role}' in '${abilityRole.id}'.`
                        )
                        continue
                    }

                    can<TariffStructure>('manage', 'Tariffs', {
                        public: false,
                    })

                    can<ApplicationGroup>('manage', 'ApplicationGroups', {
                        id: scope.applicationGroupId,
                    })

                    can<Application>('manage', 'Applications', {
                        applicationGroupId: scope.applicationGroupId,
                        ...(scope.applicationIds && {
                            id: { $in: scope.applicationIds },
                        }),
                    })
                }
            })

        case ROLES.READ_ONLY:
            return defineAbility<Ability>((can, cannot) => {
                for (const entity of validSubjects) {
                    cannot('read', entity)
                }

                for (const entity of adminValidSubjects) {
                    can('read', entity)
                }

                for (const scope of abilityRole.scope) {
                    if (
                        !scope.applicationGroupId ||
                        !(scope.allApplications || scope.applicationIds)
                    ) {
                        console.warn(
                            `Invalid scopes for role '${abilityRole.role}' in '${abilityRole.id}'.`
                        )
                        continue
                    }

                    can<TariffStructure>('read', 'Tariffs', {
                        public: false,
                    })

                    can<ApplicationGroup>('read', 'ApplicationGroups', {
                        id: scope.applicationGroupId,
                    })

                    can<Application>('read', 'Applications', {
                        applicationGroupId: scope.applicationGroupId,
                        ...(scope.applicationIds && {
                            id: { $in: scope.applicationIds },
                        }),
                    })
                }
            })

        case ROLES.MOBILE:
            return defineAbility<Ability>((can) => {
                mobileValidSubjects.forEach((ele) => {
                    can('read', ele)
                })

                for (const scope of abilityRole.scope) {
                    // Read-only scopes must always be for a specific application group:
                    if (
                        !scope.applicationGroupId ||
                        !(scope.allApplications || scope.applicationIds)
                    ) {
                        console.warn(
                            `Invalid scopes for role '${abilityRole.role}' in '${abilityRole.id}'.`
                        )
                        continue
                    }

                    can<ApplicationGroup>('read', 'ApplicationGroups', {
                        id: scope.applicationGroupId,
                    })

                    can<Application>('read', 'Applications', {
                        applicationGroupId: scope.applicationGroupId,
                        ...(scope.applicationIds && {
                            id: { $in: scope.applicationIds },
                        }),
                    })
                }
            })

        // Custom roles are for supporting special-purpose authentication:
        case ROLES.CUSTOM:
            return defineAbility<Ability>((can, _cannot) => {
                //At the bare minimum a custom user must be able to read the application / group of the scope
                can('read', 'Applications', {
                    applicationGroupId:
                        abilityRole.scope[0]?.applicationGroupId,
                    ...(abilityRole.scope[0]?.applicationIds && {
                        id: { $in: abilityRole.scope[0]?.applicationIds },
                    }),
                })

                can('read', 'ApplicationGroups', {
                    id: abilityRole.scope[0]?.applicationGroupId,
                })

                for (const ability of abilityRole.abilities ?? []) {
                    let action: Action
                    let subject: Subject
                    try {
                        action = parseAction(ability.action)
                        subject = parseSubject(ability.subject)
                    } catch (err) {
                        console.warn(err)
                        continue
                    }

                    if (subject === 'Applications') {
                        can(action, subject, {
                            applicationGroupId:
                                abilityRole.scope[0]?.applicationGroupId,
                            ...(abilityRole.scope[0]?.applicationIds && {
                                id: {
                                    $in: abilityRole.scope[0]?.applicationIds,
                                },
                            }),
                        })
                        continue
                    }

                    if (subject === 'ApplicationGroups') {
                        can(action, subject, {
                            id: abilityRole.scope[0]?.applicationGroupId,
                        })
                        continue
                    }

                    can(action, subject, ability.conditions)
                }
            })
    }

    return defineAbility<Ability>((_can, cannot) => {
        cannot('manage', 'all')
    })
}

export function getAbility(abilityRoles: AbilityRole[]): Ability | null {
    const abilities: Ability[] = []

    if (!abilityRoles || abilityRoles.length === 0) {
        // return null
        abilities.push(defineAbilityRole())
    } else {
        for (const abilityRole of abilityRoles) {
            abilities.push(defineAbilityRole(abilityRole))
        }
    }

    return abilities.reduce(
        (totalAbility, currentAbility) =>
            createMongoAbility(totalAbility.rules.concat(currentAbility.rules)),
        createMongoAbility<Ability>()
    )
}
