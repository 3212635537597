import { ENDPOINT_URLS } from './config/apiTags'
import BaseApiService, {
    ResultWithHttpCode,
    ResultWithValueAndHttpCode,
} from './rest-base.service'
import { Application } from 'switch-shared/entities/application.entity'
import { SearchApplicationResultDto } from 'switch-shared/dtos/application.dto'
import { UpdateDto } from 'switch-shared/services/crud/base.service'

class ApplicationApiService extends BaseApiService {
    constructor() {
        super(process.env.REACT_APP_BASE_API_URL?.slice(0, -1))
    }

    public async getApplications(params: {
        applicationGroupId?: string
        page?: number
        limit?: number
    }): Promise<
        ResultWithValueAndHttpCode<SearchApplicationResultDto> | undefined
    > {
        const apiResult: ResultWithValueAndHttpCode<any> | undefined =
            await this.getWithParams<any>(
                `${ENDPOINT_URLS.APPLICATION}`,
                params
            )
        if (apiResult !== undefined) {
            if (!apiResult.isSuccess) {
                return await Promise.reject(
                    new Error(
                        JSON.stringify({
                            isSuccess: false,
                            value: null,
                            errorMessage: apiResult.errorMessage,
                            httpCode: apiResult.httpCode,
                        })
                    )
                )
            }
            return {
                isSuccess: true,
                value: apiResult.value,
                errorMessage: '',
                httpCode: apiResult.httpCode,
            }
        }
    }

    public async createApplication(newAppObject: {}): Promise<
        ResultWithValueAndHttpCode<any> | undefined
    > {
        const apiResult: ResultWithValueAndHttpCode<any> | undefined =
            await this.post<any>(`${ENDPOINT_URLS.APPLICATION}`, newAppObject)
        if (apiResult !== undefined) {
            if (!apiResult.isSuccess) {
                return await Promise.reject(
                    new Error(
                        JSON.stringify({
                            isSuccess: false,
                            value: null,
                            errorMessage: apiResult.errorMessage,
                            httpCode: apiResult.httpCode,
                        })
                    )
                )
            }

            return {
                isSuccess: true,
                value: newAppObject,
                errorMessage: '',
                httpCode: apiResult.httpCode,
            }
        }
    }

    public async deleteApplication(
        appToDeleteId: string
    ): Promise<ResultWithHttpCode | undefined> {
        const apiResult: ResultWithHttpCode | undefined = await this.delete(
            `${ENDPOINT_URLS.APPLICATION}/${appToDeleteId}`
        )
        if (apiResult !== undefined) {
            if (!apiResult.isSuccess) {
                return await Promise.reject(
                    new Error(
                        JSON.stringify({
                            isSuccess: false,
                            value: null,
                            errorMessage: apiResult.errorMessage,
                            httpCode: apiResult.httpCode,
                        })
                    )
                )
            }
            return {
                isSuccess: true,
                errorMessage: '',
                httpCode: apiResult.httpCode,
            }
        }
    }

    public async updateApplication(
        id: string,
        app: UpdateDto<Application>
    ): Promise<ResultWithValueAndHttpCode<any> | undefined> {
        const apiResult: ResultWithValueAndHttpCode<any> | undefined =
            await this.patch<any>(`${ENDPOINT_URLS.APPLICATION}/${id}`, app)
        if (apiResult !== undefined) {
            if (!apiResult.isSuccess) {
                return await Promise.reject(
                    new Error(
                        JSON.stringify({
                            isSuccess: false,
                            value: null,
                            errorMessage: apiResult.errorMessage,
                            httpCode: apiResult.httpCode,
                        })
                    )
                )
            }
            return {
                isSuccess: true,
                value: apiResult.value,
                errorMessage: '',
                httpCode: apiResult.httpCode,
            }
        }
    }

    // check types
    public async uniqueHumanIdCheck(params: {
        collectionName: string
        humanId: string
    }): Promise<ResultWithValueAndHttpCode<any> | undefined> {
        const apiResult: ResultWithValueAndHttpCode<any> | undefined =
            await this.getWithParams<any>(
                `${ENDPOINT_URLS.APPLICATION}`,
                params
            )
        if (apiResult !== undefined) {
            if (!apiResult.isSuccess) {
                return await Promise.reject(
                    new Error(
                        JSON.stringify({
                            isSuccess: false,
                            value: null,
                            errorMessage: apiResult.errorMessage,
                            httpCode: apiResult.httpCode,
                        })
                    )
                )
            }
            return {
                isSuccess: true,
                value: apiResult.value,
                errorMessage: '',
                httpCode: apiResult.httpCode,
            }
        }
    }
}

export default ApplicationApiService
